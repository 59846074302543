import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Usuario } from 'src/app/model/usuario';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

    usuario: string;
    password: string;

    constructor(private router: Router, 
      private route: ActivatedRoute, 
      private http: HttpClient,
      private dataService: DataService,
      private httpService: HttpService) { }

    ngOnInit(): void {
      this.usuario = "";
      this.password = "";
    }
  
    // On Signup link click
    onLogin() {
      let credenciales : {login: string, password: string} = {
        login: this.usuario,
        password: this.password
      };
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      
      
      this.http.post(`${this.httpService.getURL()}/login`, (credenciales), {headers})
      .subscribe(data => {
        let usuario = data as Usuario;
        this.dataService.setUsuarioLogueado(data as Usuario);
        if(usuario.cajero === true) {
          this.router.navigate(['pdv/pdv']);  
        } else {
          this.router.navigate(['dashboard/e-commerce'], { relativeTo: this.route.parent });
        }
        
      }, error => {
        alert(`ERROR! ${error.error.mensaje}`);
        console.error(`ERROR! ${error.error.mensaje}`);
      })


      
    }

}
