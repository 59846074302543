import { Injectable } from '@angular/core';
import { Usuario } from '../model/usuario';
import { Cita } from '../model/cita';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  constructor() { }

  getUsuarioLogueado(): Usuario {
    return JSON.parse(localStorage.getItem('usuarioLogueado')); 
  }

  setUsuarioLogueado(usuario: Usuario) {
    localStorage.setItem('usuarioLogueado', JSON.stringify(usuario))
  }

  setCaja(idCaja: number) {
    localStorage.setItem('caja', idCaja.toString());
  }

  geetCaja() : number {
    return parseInt(localStorage.getItem("caja"));
  }

  setCita(cita: Cita) {
    localStorage.setItem('cita', JSON.stringify(cita))
  }

  getCita(): Cita {
    let cita: Cita = JSON.parse(localStorage.getItem('cita'));
    localStorage.removeItem('cita');
    return cita; 
  }

  cleanStorage() {
    localStorage.removeItem('cita');
    localStorage.removeItem('caja');
    localStorage.removeItem('usuarioLogueado');
  }

}
