import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    {
        path: '', title: 'Dashboard', icon: 'bx bx-home-circle', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/dashboard/e-commerce', title: 'Dashboard', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permisos: ['SUPER'] },
        ],
        permisos: ['SUPER']
    },

    {
        path: '', title: 'Sistema', icon: 'bx bx-category', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/usuarios', title: 'Usuarios', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permisos: ['SUPER'] },
        ], permisos: ['SUPER']
    },

    {
        path: '', title: 'Operaciones', icon: 'bx bx-cog', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/operaciones/promociones', title: 'Promociones', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permisos: ['SUPER'] },
            { path: '/operaciones/menu', title: 'Menú', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permisos: ['SUPER'] },
            { path: '/operaciones/eventos', title: 'Eventos', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permisos: ['SUPER'] },
        ], permisos: ['SUPER']
    },
    
];