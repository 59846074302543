import { Injectable } from '@angular/core';
import { ToastService } from '../ng-components/toast/toast-service';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(private toastService: ToastService) { }

  exito(mensaje: string) {
    this.toastService.show(mensaje, { classname: 'bg-success text-light', delay: 2500 });
  }

  error(mensaje: string) {
    this.toastService.show(mensaje, { classname: 'bg-danger text-light', delay: 5000 });
  }
  
}
