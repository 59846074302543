import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "./sidebar.service";

import * as $ from 'jquery';
import { DataService } from 'src/app/services/data.service';
import { RouteInfo } from './sidebar.metadata';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    
    public menuItems: any[];

  
    constructor( public sidebarservice: SidebarService,private router: Router, private dataService: DataService) {

        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {

                this.toggleSidebar();
                // Hide loading indicator
               
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }

        
    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
        
        if ($(".wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $(".wrapper").removeClass("nav-collapsed");
            $(".sidebar-wrapper").unbind( "hover");
        } else {
            $(".wrapper").addClass("nav-collapsed");
            $(".sidebar-wrapper").hover(
                function () {
                    $(".wrapper").addClass("sidebar-hovered");
                },
                function () {
                    $(".wrapper").removeClass("sidebar-hovered");
                }
            )
      
        }

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }
    

    ngOnInit() {
        this.dataService.getUsuarioLogueado().roles;
        const permisos: string[] = this.dataService.getUsuarioLogueado().roles.split(",").map(role => role.trim().replace(/^ROLE_/, ''));
        this.menuItems = ROUTES.filter(menuItem => {
            return permisos.some(permiso => menuItem.permisos.includes(permiso));

            //return menuItem.permisos.some(permiso => permisos.includes(permiso));
        });

        for(let i = 0; i < this.menuItems.length; i++) {
            this.menuItems[i].submenu = this.menuItems[i].submenu.filter(submenuItem => {
                return permisos.some(permiso => submenuItem.permisos.includes(permiso));

                //return submenuItem.permisos.some(permiso => permisos.includes(permiso));
            });
        }
        
        $.getScript('./assets/js/app-sidebar.js');

    }

    filterSubmenuItems(route: RouteInfo, permisos: string[]): RouteInfo {
        return {
          ...route,
          submenu: route.submenu.filter(submenuItem => submenuItem.permisos.some(permiso => permisos.includes(permiso)))
        };
      }

}
