import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Route, Router } from '@angular/router';
import { AlertasService } from './alertas.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private url = 'https://papicash.mx:8080';
  //private url = 'http://localhost:8080';
  constructor(private http: HttpClient,
    private dataService: DataService,
    private router: Router,
    private alertasService: AlertasService) { }

    getURL(): string {
      return this.url;
    }
    
  async get(endpoint: string) {
    try {
      return await this.http.get(`${this.url}/${endpoint}`, this.getHeaders()).toPromise();
    } catch(error) {
      if(error.status === 403 || error.status === 401) {
        this.router.navigate(['/']);
      } else {
        this.alertasService.error(`Error al realizar la operacion GET al servidor!\n ${error.error.mensaje}`);
        console.error(JSON.stringify(error));
        throw error;
      }
    }
  }


  async getPDF(endpoint: string) {
    try {
      const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.dataService.getUsuarioLogueado().token}`)
      .set('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers,
      responseType: 'blob' as 'json'
    };

      return await this.http.get(`${this.url}/${endpoint}`, httpOptions).toPromise();
    } catch(error) {
      if(error.status === 403 || error.status === 401) {
        this.router.navigate(['/']);
      } else {
        this.alertasService.error(`Error al realizar la operacion GET al servidor!\n ${error.error.mensaje}`);
        console.error(JSON.stringify(error));
        throw error;
      }
    }
  }

  async getPDFConFiltros(endpoint: string, filtros: any) {
    try {
      const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.dataService.getUsuarioLogueado().token}`)
      .set('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers,
      responseType: 'blob' as 'json'
    };

      return await this.http.post(`${this.url}/${endpoint}`, filtros, httpOptions).toPromise();
    } catch(error) {
      if(error.status === 403 || error.status === 401) {
        this.router.navigate(['/']);
      } else {
        this.alertasService.error(`Error al realizar la operacion POST al servidor!\n ${error.error.mensaje}`);
        console.error(JSON.stringify(error));
        throw error;
      }
    }
  }

  async post(endpoint: string, data: any) {
    try {
      return await this.http.post(`${this.url}/${endpoint}`, data, this.getHeaders()).toPromise();
    } catch(error) {
      if(error.status === 403 || error.status === 401) {
        this.router.navigate(['/']);
      } else {
        this.alertasService.error(`Error al realizar la operacion POST al servidor!\n ${error.error.mensaje}`);
        console.error(JSON.stringify(error));
        throw error;
      }
    }
    
  }

  async put(endpoint: string, data: any) {
    try {
      return await this.http.put(`${this.url}/${endpoint}`, data, this.getHeaders()).toPromise();
    } catch(error) {
      if(error.status === 403 || error.status === 401) {
        this.router.navigate(['/']);
      } else {
        this.alertasService.error(`Error al realizar la operacion PUT al servidor!\n ${error.error.mensaje}`);
        console.error(JSON.stringify(error));
        throw error;
      }
    }
  }

  async uploadFile(endpoint: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.dataService.getUsuarioLogueado().token}`);
/*
    try {
      return await this.http.post(`${this.url}/${endpoint}`, formData, { headers: headers }).toPromise();
    } catch (error) {
      this.alertasService.error(`Error al realizar la operacion POST al servidor!\n ${error.error.mensaje}`);
      console.error(JSON.stringify(error));
      throw error;
    }
    */

    this.http.post(`${this.url}/${endpoint}`, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log('Upload Progress: ' + Math.round(event.loaded / event.total! * 100) + '%');
      } else if (event.type === HttpEventType.Response) {
        console.log('Upload Complete');
        return true;
      }
    });
  }

  private getHeaders() {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.dataService.getUsuarioLogueado().token}`)
      .set('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers,
    };

    return httpOptions;

  }
}
