import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'ng-components',
        loadChildren: () => import('../../ng-components/ng-components.module').then(m => m.NgComponentsModule)
    },
    {
        path: 'usuarios', 
        loadChildren: () => import('../../sistema/usuarios/usuarios.module').then(m => m.UsuariosModule)
    },
    {
        path: 'operaciones', 
        loadChildren: () => import('../../operaciones/operaciones.module').then(m => m.OperacionesModule)
    },
];